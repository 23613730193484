import { ImageWrap } from '@truphone/gatsby-wrappers'
import React from 'react'

export default function PrepaidPage(props) {
  return (
    <div className="container mx-auto px-4 lg:flex pt-20 pb-10 lg:pb-20">
      <div className="lg:w-1/2">
        <h1 className="heron leading-none uppercase" style={{ fontSize: 'clamp(3rem, 8vw, 5rem)' }}>
          {props.title}
        </h1>
        <div
          className="text-base link-blue mt-6"
          dangerouslySetInnerHTML={{ __html: props.text }}
        />
        {props.whiteBox && (
          <div className="mt-8 p-6 bg-white shadow rounded-lg lg:mr-10">
            {props.whiteBox.title && <h3 className="text-xl mb-2">{props.whiteBox.title}</h3>}
            {props.whiteBox.text && <p className="text-xs mb-4">{props.whiteBox.text}</p>}
            {props.whiteBox.ctas && (
              <div className="flex flex-col lg:flex-row -mx-2">
                {props.whiteBox.ctas.map((cta, i) => {
                  return (
                    <a
                      key={`cta-${i}`}
                      href={cta.href}
                      className={`${
                        i === 0 ? 'bg-pink' : 'bg-blue-100'
                      } text-white text-center px-4 py-3 font-bold uppercase tracking-wider text-sm rounded m-2`}
                    >
                      {cta.text}
                    </a>
                  )
                })}
              </div>
            )}
          </div>
        )}
      </div>
      <div className="lg:w-1/2 flex justify-center items-center">
        {props.image && <ImageWrap className="m-auto max-w-[400px]" {...props.image} />}
      </div>
    </div>
  )
}
