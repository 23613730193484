import { Select, Tooltip } from '@truphone/core-components'
import React, { useState, useEffect, useRef } from 'react'
import Countries from '../data/b2c-rates-countries.json'
import { Typeahead, Menu, MenuItem } from 'react-bootstrap-typeahead'
import 'react-bootstrap-typeahead/css/Typeahead.css'
import './style.css'
import { ArrowSimple } from '@truphone/icons'

let countriesFilter = Countries.map((x) => ({ text: x['Country Name'], value: x.ISO }))
let selectedCountry = countriesFilter.find((x) => x.value === 'GB')

export default function PrepaidRateChecker(props) {
  const [selectedFrom, setSelectedFrom] = useState([selectedCountry])
  const [selectedTo, setSelectedTo] = useState([selectedCountry])
  const [previousFrom, setPreviousFrom] = useState([selectedCountry])
  const [previousTo, setPreviousTo] = useState([selectedCountry])
  const [selectedCurrency, setSelectedCurrency] = useState(props.defaultCurrency)
  const emptyRates = {
    Outgoing: { Landline: '-', Mobile: '-', Sms: '-' },
    Incoming: { Landline: '-', Mobile: '-', Sms: '-' },
    Data: '-'
  }
  const [rates, setRates] = useState(emptyRates)
  const ref = useRef()
  const ref2 = useRef()
  useEffect(() => {
    const fetchRates = async () => {
      if (!selectedFrom[0] || !selectedTo[0]) {
        return
      }

      if (selectedFrom[0] && selectedTo[0]) {
        var response = await fetch(
          `https://web.truphone.com/api/b2crates/${selectedFrom[0].value}/${selectedTo[0].value}/${selectedCurrency}/0`
        )

        if (response.ok) {
          const res = await response.json()
          if (!!res && res !== 'null') {
            setRates(JSON.parse(res))
          }
        }
      }
    }

    fetchRates()
  }, [selectedFrom, selectedTo, selectedCurrency])

  const toFixedWithZero = (value) => {
    if (value && value !== '-') {
      return value.slice(0, 1) + Number.parseFloat(value.slice(1)).toFixed(2)
    } else {
      return value
    }
  }
  const labelsTop = Object.getOwnPropertyNames(props.labelsTop).map(
    (el) => el.substr(0, 1).toUpperCase() + el.substr(1)
  )
  const labels = Object.getOwnPropertyNames(props.labelsLeft).map(
    (el) => el.substr(0, 1).toUpperCase() + el.substr(1)
  )

  const currencies = [
    {
      text: '$ (AUD)',
      value: 'AUD'
    },
    {
      text: '£ (GBP)',
      value: 'GBP'
    },

    {
      text: '€ (EUR)',
      value: 'EUR'
    },
    {
      text: '$ (USD)',
      value: 'USD'
    }
  ]

  const showRowsMobile = (currentLabel) => {
    return labels.map((label, i) => {
      return label === 'Data' ? (
        <div className="text-sm flex p-4" key={`mobile-data-${i}`}>
          <div className="w-1/2">
            <div className="w-full flex items-center">
              <img
                className="w-6 mr-2"
                src="/images/prepaid/icon-account-mobile-data.svg"
                alt={label}
              />
              <p className="text-base font-bold"> {props.labelsLeft[label.toLowerCase()]}</p>
            </div>
          </div>
          {currentLabel === 'Mobile' && (
            <div className="w-1/2">
              <div className="w-full flex items-center">
                {rates[label] &&
                  rates[label] !== '0' &&
                  toFixedWithZero(rates[label]) + ' ' + props.perData}
                <span className="relative ml-10">
                  <Tooltip isRelative tooltip={props.tooltip} />
                </span>
              </div>
            </div>
          )}
        </div>
      ) : (
        <div className="text-sm flex p-4" key={`mobile-data-${i}`}>
          <div className="w-1/2">
            <div className="w-full flex items-center whitespace-nowrap">
              {label === 'Outgoing' ? (
                <img className="w-4 mr-4" src="/images/prepaid/arrow.svg" alt={label} />
              ) : (
                <img className="w-4 mr-4 -rotate-180" src="/images/prepaid/arrow.svg" alt={label} />
              )}
              <p className="text-base font-bold"> {props.labelsLeft[label.toLowerCase()]}</p>
            </div>
          </div>

          <div className="w-1/2">
            <div className="w-full flex items-center">
              <p>
                {rates[label][currentLabel] &&
                  rates[label][currentLabel] !== '0' &&
                  toFixedWithZero(rates[label][currentLabel]) + ' ' + props.perMin}
              </p>
              {label === 'Outgoing' && (
                <span className="relative ml-10">
                  <Tooltip isRelative tooltip={props.tooltip} />
                </span>
              )}
            </div>
          </div>
        </div>
      )
    })
  }
  const showRows = () => {
    return labels.map((label, i) => {
      return label === 'Data' ? (
        <div className="flex w-full p-4" key={`label-data-${i}`}>
          <div className="flex items-center lg:w-1/4">
            <img
              className="w-6 mr-2"
              src="/images/prepaid/icon-account-mobile-data.svg"
              alt={label}
            />
            <p className="text-base font-bold"> {props.labelsLeft[label.toLowerCase()]}</p>
          </div>
          <div className="relative lg:text-center lg:w-1/4">
            <p className="text-base w-full flex justify-center">
              {rates[label] !== '0' && toFixedWithZero(rates[label]) + ' ' + props.perData}

              <span className="relative ml-10">
                <Tooltip isRelative tooltip={props.tooltip} />
              </span>
            </p>
          </div>
        </div>
      ) : (
        <div className="flex w-full p-4" key={`label-calls-${i}`}>
          <div className="flex items-center lg:w-1/4">
            {label === 'Outgoing' ? (
              <img className="w-4 mr-4" src="/images/prepaid/arrow.svg" alt={label} />
            ) : (
              <img className="w-4 mr-4 -rotate-180" src="/images/prepaid/arrow.svg" alt={label} />
            )}
            <p className="text-base font-bold w-1/2"> {props.labelsLeft[label.toLowerCase()]}</p>
          </div>
          <div className="relative w-1/4 text-center">
            <p className="text-base w-full flex justify-center">
              {rates[label].Mobile !== '0' &&
                toFixedWithZero(rates[label].Mobile) + ' ' + props.perMin}
              {label === 'Outgoing' && (
                <span className="relative ml-10">
                  <Tooltip isRelative tooltip={props.tooltip} />
                </span>
              )}
            </p>
          </div>
          <div className="relative w-1/4 text-center">
            <p className="text-base w-full flex justify-center">
              {rates[label].Landline !== '0' &&
                toFixedWithZero(rates[label].Landline) + ' ' + props.perMin}
              {label === 'Outgoing' && (
                <span className="relative ml-10">
                  <Tooltip isRelative tooltip={props.tooltip} />
                </span>
              )}
            </p>
          </div>
          <div className="w-1/4 text-center text-base">
            {rates[label].Sms !== '0' && toFixedWithZero(rates[label].Sms) + ' ' + props.perText}
          </div>
        </div>
      )
    })
  }
  return (
    <div className="bg-grey-100 py-20 min-h-screen overflow-x-hidden">
      <div className="container mx-auto px-4 relative">
        <img
          className="hidden md:block absolute top-[100px] left-[-250px]"
          src="/images/prepaid/images-world-map-left.svg"
          alt="map-left"
        />
        <img
          className="hidden md:block absolute top-[100px] right-[-320px]"
          src="/images/prepaid/images-world-map-right.svg"
          alt="map-right"
        />
        <div className="max-w-[900px] mx-auto">
          <p className="uppercase tracking-wider font-bold text-center mb-4">{props.topTitle}</p>
          <h1
            className="heron leading-none text-center uppercase"
            style={{ fontSize: 'clamp(3rem, 8vw, 5rem)' }}
          >
            {props.title}
          </h1>
          <div
            className="text-base link-blue mt-6 mb-10 text-center"
            dangerouslySetInnerHTML={{ __html: props.text }}
          />

          <div className="sm:flex relative">
            <div className="sm:w-1/2">
              <p className="text-xs mt-3 mb-1 sm:tracking-wider">{props.callingFrom}</p>
              <div className="relative sm:rounded-b-none bg-white shadow rounded border border-grey-400 sm:shadow-none">
                <Typeahead
                  id="country-selector"
                  placeholder={props.searchPlaceholderFrom}
                  labelKey="text"
                  options={countriesFilter}
                  selected={selectedFrom}
                  onChange={(v) => {
                    setSelectedFrom(v)
                    if (v.length !== 0) {
                      setPreviousFrom(v)
                    }
                  }}
                  onFocus={() => {
                    setSelectedFrom([])
                  }}
                  onBlur={() => {
                    if (!selectedFrom.length) {
                      setSelectedFrom(previousFrom)
                    }
                  }}
                  minLength={0}
                  shouldSelect={false}
                  positionFixed={true}
                  paginate={false}
                  maxResults={countriesFilter.length}
                  emptyLabel={props.noMatchesMessage}
                  ref={ref}
                  renderInput={({ inputRef, referenceElementRef, ...inputProps }) => {
                    let iso = ''
                    if (selectedFrom && selectedFrom[0]) {
                      iso = selectedFrom[0].value
                    }

                    return (
                      <div className="flex items-center">
                        {inputProps.value && iso && (
                          <div className="relative flex justify-center ml-4 w-7 h-7 overflow-hidden rounded-full shrink-0 shadow">
                            <img
                              className="h-full max-w-none"
                              src={`/images/flags/${iso.toLocaleLowerCase()}.svg`}
                              alt={inputProps.value}
                            />
                          </div>
                        )}
                        <input
                          {...inputProps}
                          className="pr-12 text-ellipsis focus:ring-0 w-full outline-none border-none h-14 lg:text-2xl"
                          ref={(input) => {
                            inputRef(input)
                            referenceElementRef(input)
                          }}
                        />
                      </div>
                    )
                  }}
                  renderMenu={(results, menuProps) => {
                    return (
                      <Menu {...menuProps} className="bg-white z-10">
                        {results.length === 0 ? (
                          <div className="px-3 py-2">{props.noMatchesMessage}</div>
                        ) : (
                          results.map((result, index) => (
                            <MenuItem
                              option={result}
                              position={index}
                              iso={result.iso}
                              key={result.iso}
                            >
                              <div className="px-3 py-2 hover:bg-grey-100 ">{result.text}</div>
                            </MenuItem>
                          ))
                        )}
                      </Menu>
                    )
                  }}
                />
                <div
                  onClick={() => {
                    ref.current.clear()
                    setSelectedFrom([])
                    ref.current.focus()
                  }}
                  className="cursor-pointer text-grey-500 absolute bottom-0 right-0 w-12 h-full flex px-3 py-3 border-l border-grey-400"
                >
                  <ArrowSimple className="w-6 h-8 pointer-events-none" />
                </div>
              </div>
            </div>
            <div className=" sm:w-1/2">
              <p className="text-xs mt-3 mb-1 sm:tracking-wider">{props.callingTo}</p>
              <div className="relative sm:rounded-b-none bg-white shadow rounded border border-grey-400 sm:shadow-none">
                <Typeahead
                  id="country-selector-to"
                  placeholder={props.searchPlaceholderTo}
                  labelKey="text"
                  options={countriesFilter}
                  selected={selectedTo}
                  onChange={(v) => {
                    setSelectedTo(v)
                    if (v.length !== 0) {
                      setPreviousTo(v)
                    }
                  }}
                  onFocus={() => {
                    setSelectedTo([])
                  }}
                  onBlur={(v) => {
                    if (!selectedTo.length) {
                      setSelectedTo(previousTo)
                    }
                  }}
                  minLength={0}
                  shouldSelect={false}
                  positionFixed={true}
                  paginate={false}
                  maxResults={countriesFilter.length}
                  emptyLabel={props.noMatchesMessage}
                  ref={ref2}
                  renderInput={({ inputRef, referenceElementRef, ...inputProps }) => {
                    let iso = ''
                    if (selectedTo && selectedTo[0]) {
                      iso = selectedTo[0].value
                    }

                    if (!inputProps.value) {
                      setSelectedFrom(previousFrom)
                    }

                    return (
                      <div className="flex items-center">
                        {inputProps.value && iso && (
                          <div className="relative flex items-center justify-center ml-4 w-7 h-7 overflow-hidden rounded-full shrink-0 shadow">
                            <img
                              className="h-full max-w-none"
                              src={`/images/flags/${iso.toLocaleLowerCase()}.svg`}
                              alt={inputProps.value}
                            />
                          </div>
                        )}
                        <input
                          {...inputProps}
                          className="pr-12 text-ellipsis focus:ring-0 w-full outline-none border-none h-14 lg:text-2xl"
                          ref={(input) => {
                            inputRef(input)
                            referenceElementRef(input)
                          }}
                        />
                      </div>
                    )
                  }}
                  renderMenu={(results, menuProps) => (
                    <Menu {...menuProps} className="bg-white z-10">
                      {results.length === 0 ? (
                        <div className="px-3 py-2">{props.noMatchesMessage}</div>
                      ) : (
                        results.map((result, index) => (
                          <MenuItem
                            option={result}
                            position={index}
                            iso={result.iso}
                            key={result.iso}
                          >
                            <div className="p-2 hover:bg-grey-100">{result.text}</div>
                          </MenuItem>
                        ))
                      )}
                    </Menu>
                  )}
                />
                <div
                  onClick={() => {
                    ref2.current.clear()
                    setSelectedTo([])
                    ref2.current.focus()
                  }}
                  className="cursor-pointer text-grey-500 absolute bottom-0 right-0 w-12 h-full flex px-3 py-3 border-l border-grey-400"
                >
                  <ArrowSimple className="w-6 h-8 pointer-events-none" />
                </div>
              </div>
            </div>
          </div>
          <div className="sm:bg-white sm:rounded-b mt-4 sm:mt-0 w-full shadow sm:shadow-sm">
            <Select
              isRelative
              options={currencies}
              value={selectedCurrency}
              onChange={(value) => setSelectedCurrency(value)}
            />
          </div>

          {labelsTop && (
            <>
              <div className="relative my-10 bg-white shadow rounded">
                <div className="flex flex-col lg:flex-row border-b border-grey-400">
                  <div className="hidden lg:block p-4 text-center font-bold md:w-1/4">
                    <p className="text-bold text-sm">&nbsp;</p>
                  </div>
                  {labelsTop.map((label, i) => {
                    return (
                      <div className="flex flex-col lg:flex-row lg:w-1/4">
                        <div
                          key={`label-${i}`}
                          className="border-b lg:border-none border-grey-400 w-full p-4 lg:text-center font-bold"
                        >
                          <p className="text-bold text-base">
                            {props.labelsTop[label.toLowerCase()]}
                            {/* {i === 0 ? label.mobile : i === 1 ? label.landline : label.sms} */}
                          </p>
                        </div>
                        <div className="flex flex-col lg:hidden">{showRowsMobile(label)}</div>
                      </div>
                    )
                  })}
                </div>
                <div className="hidden lg:flex flex-col">{showRows()}</div>
              </div>
            </>
          )}

          <div
            className="text-xs mt-12 link-blue"
            dangerouslySetInnerHTML={{ __html: props.disclaimer }}
          />
        </div>
      </div>
    </div>
  )
}
